const APP_REGION = String(
  import.meta.env.REACT_APP_APP_REGION || process.env['REACT_APP_APP_REGION'] || '',
).toUpperCase();
const ENV_CUSTOM_ENV = String(
  import.meta.env.REACT_APP_CUSTOM_NODE_ENV || process.env.REACT_APP_CUSTOM_NODE_ENV || 'development',
);
const API_ENDPOINT = String(
  import.meta.env.REACT_APP_API_BASE_ENDPOINT || process.env.REACT_APP_API_BASE_ENDPOINT || '',
);
const EU_HOST = String(import.meta.env.REACT_APP_EU_HOST || process.env['REACT_APP_EU_HOST'] || '');
const TR_HOST = String(import.meta.env.REACT_APP_TR_HOST || process.env['REACT_APP_TR_HOST'] || '');
const UK_HOST = String(import.meta.env.REACT_APP_UK_HOST || process.env['REACT_APP_UK_HOST'] || '');

const FRONTEND_BASE_URL = String(import.meta.env.REACT_APP_BASE_URL || process.env['REACT_APP_BASE_URL'] || '');

const WS_ENDPOINT = String(import.meta.env.REACT_APP_WS_ENDPOINT || process.env.REACT_APP_WS_ENDPOINT || '');
const CDN_URL = String(import.meta.env.REACT_APP_CDN_URL || process.env.REACT_APP_CDN_URL || '');

const MSG_ENDPOINT = String(import.meta.env.REACT_APP_MSG_ENDPOINT || process.env.REACT_APP_MSG_ENDPOINT || '');

const KNOWLEDGE_BASE_URL = String(
  import.meta.env.REACT_APP_KNOWLEDGE_BASE_URL || process.env.REACT_APP_KNOWLEDGE_BASE_URL || '',
);

const TEST_PROVIDERS_IDS = String(
  import.meta.env.REACT_APP_TEST_PROVIDERS_IDS || process.env.REACT_APP_TEST_PROVIDERS_IDS || '',
);

const LOCAL_DEVELOPMENT = import.meta.env.ENV_CUSTOM_ENV === 'development' || ENV_CUSTOM_ENV === 'development';

const SENTRY_DSN = String(import.meta.env.REACT_APP_SENTRY_DSN || process.env['REACT_APP_SENTRY_DSN'] || '');
const RELEASE = String(
  import.meta.env.REACT_APP_SENTRY_RELEASE || process.env['REACT_APP_SENTRY_RELEASE'] || 'development',
);

export const ENV = {
  FRONTEND_BASE_URL,
  APP_REGION,
  API_ENDPOINT,
  WS_ENDPOINT,
  CDN_URL,
  MSG_ENDPOINT,
  TEST_PROVIDERS_IDS,
  LOCAL_DEVELOPMENT,
  KNOWLEDGE_BASE_URL,
  EU_HOST,
  TR_HOST,
  UK_HOST,
  SENTRY_DSN,
  RELEASE,
  ENV_CUSTOM_ENV,
};
