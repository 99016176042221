import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { amplitudeLogger, FAQ_CLICK } from 'common/amplitude';
import { CURRENT_REGION } from 'common/config/regions/config';
import { Regions } from 'common/config/regions/types';
import { regionLinks } from 'common/constants/links';
import { ContactType } from 'common/queries/types';
import routes from 'common/routes';
import { LanguageChanger } from 'components/LanguageChanger';
import { MessengerHeaderButton } from 'components/Messenger/MessengerHeaderButton/MessengerHeaderButton';
import LogoSource from 'images/logo.svg';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './DesktopMenu.module.less';

interface DesktopMenuProps {
  selectedKeys: string[];
  contact: ContactType;
  onLogout: () => void;
}

export const DesktopMenu: FC<DesktopMenuProps> = ({ selectedKeys, contact, onLogout }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.logoCnt}>
        <Link to={routes.root} className={styles.logoLink}>
          <img src={LogoSource} alt="logo" />
          &nbsp;
          <span>for Partners</span>
        </Link>
      </div>
      <Menu className={styles.menu} theme="light" mode="horizontal" selectedKeys={selectedKeys}>
        {!contact?.provider?.onboardingCompleted ? (
          <Menu.Item key="onboarding">
            <Link to={routes.profileOnboarding}>{t('header:menuItems.firstSteps')}</Link>
          </Menu.Item>
        ) : null}
        <Menu.Item key="offers">
          <Link to={routes.offers}>{t('header:menuItems.jobBoard')}</Link>
        </Menu.Item>
        <Menu.Item key="orders">
          <Link to={routes.orders}>{t('header:menuItems.myOrders')}</Link>
        </Menu.Item>
        {CURRENT_REGION !== Regions.TR ? (
          <Menu.Item key="invoices">
            <Link to={routes.invoices}>{t('header:menuItems.finance')}</Link>
          </Menu.Item>
        ) : null}
        <Menu.Item key="knowledgebase">
          <a
            href={regionLinks.knowledgeBase}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => amplitudeLogger(FAQ_CLICK)}
          >
            {t('header:menuItems.faqAndHelp')}
          </a>
        </Menu.Item>
        <Menu.Item key="support">
          <Link to={routes.support}>{t('header:menuItems.support')}</Link>
        </Menu.Item>
        <Menu.Item key="calendar">
          <Link to={routes.calendar}>{t('header:menuItems.calendar')}</Link>
        </Menu.Item>
        <Menu.Item key="details">
          <Link to={routes.profileDetails}>
            <UserOutlined /> {t('header:menuItems.profile')}
          </Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <Button type="link" onClick={() => void onLogout()} className={styles.logoutBtn}>
            <LogoutOutlined /> {t('header:menuItems.logOut')}
          </Button>
        </Menu.Item>
      </Menu>
      <LanguageChanger hasContact />
      <div className={styles.messengerBtn}>
        <MessengerHeaderButton />
      </div>
    </div>
  );
};
